const locales = {
    'en-US': {
      "hello": "Hello, how can I assist you today?",
      "how are you?": "How are you feeling today?",
      "good morning": "Good morning! How can I help you?",
      "heart attack": "A heart attack is a serious medical emergency that requires immediate treatment.",
      "diabetes": "Diabetes is a condition that affects how your body processes blood sugar.",
      "blood pressure": "Blood pressure is the force of blood pushing against the walls of your arteries.",
      "asthma": "Asthma is a condition where your airways narrow and swell, making it difficult to breathe.",
      "cancer": "Cancer is a group of diseases where abnormal cells divide without control.",
      "flu": "The flu is a contagious respiratory illness caused by influenza viruses.",
      "stroke": "A stroke occurs when blood flow to part of your brain is interrupted.",
      "fever": "A fever is a temporary increase in body temperature, often due to infection.",
      "headache": "A headache is pain or discomfort in the head or face area.",
      "vomiting": "Vomiting is the forceful expulsion of stomach contents through the mouth.",
      "allergy": "An allergy is a condition in which your immune system reacts abnormally to a foreign substance.",
      "infection": "An infection is caused by harmful microorganisms like bacteria, viruses, or fungi.",
      "surgery": "Surgery is a medical procedure involving an incision to treat a disease or condition.",
      "medication": "Medication refers to drugs used to treat or manage health conditions.",
      "doctor": "A doctor is a trained medical professional who diagnoses and treats illnesses.",
      "nurse": "A nurse provides care and support to patients in hospitals and other healthcare settings.",
      "hospital": "A hospital is a healthcare facility for the diagnosis, treatment, and care of patients.",
      "treatment": "Treatment involves methods or procedures used to alleviate symptoms or cure a condition.",
      "vaccine": "A vaccine helps protect you from diseases by boosting your immune system.",
      "prescription": "A prescription is a medical order for medication provided by a doctor.",
      "symptom": "A symptom is a physical or mental feature indicating a condition or disease.",
      "diagnosis": "A diagnosis is the identification of a disease or condition based on symptoms and tests.",
      "patient": "A patient is an individual who receives medical care or treatment.",
    },
    'it-IT': {
      "hello": "Ciao, come posso aiutarti oggi?",
      "how are you?": "Come ti senti oggi?",
      "good morning": "Buongiorno! Come posso esserti utile?",
      "heart attack": "Un infarto è un'emergenza medica grave che richiede trattamento immediato.",
      "diabetes": "Il diabete è una condizione che influisce su come il corpo gestisce lo zucchero nel sangue.",
      "blood pressure": "La pressione sanguigna è la forza del sangue che spinge contro le pareti delle arterie.",
      "asthma": "L'asma è una condizione in cui le vie respiratorie si restringono e si infiammano, rendendo difficile respirare.",
      "cancer": "Il cancro è un gruppo di malattie in cui le cellule anormali si dividono senza controllo.",
      "flu": "L'influenza è una malattia respiratoria contagiosa causata dai virus influenzali.",
      "stroke": "Un ictus si verifica quando il flusso sanguigno verso una parte del cervello viene interrotto.",
      "fever": "La febbre è un aumento temporaneo della temperatura corporea, spesso causato da un'infezione.",
      "headache": "Il mal di testa è un dolore o disagio nella zona della testa o del viso.",
      "vomiting": "Il vomito è l'espulsione forzata del contenuto dello stomaco attraverso la bocca.",
      "allergy": "Un'allergia è una condizione in cui il sistema immunitario reagisce in modo anomalo a una sostanza estranea.",
      "infection": "Un'infezione è causata da microrganismi dannosi come batteri, virus o funghi.",
      "surgery": "La chirurgia è una procedura medica che comporta un'incisione per trattare una malattia o una condizione.",
      "medication": "Il medicinale si riferisce a farmaci utilizzati per trattare o gestire condizioni di salute.",
      "doctor": "Un dottore è un professionista medico formato che diagnostica e tratta malattie.",
      "nurse": "Un'infermiera fornisce assistenza e supporto ai pazienti in ospedali e altre strutture sanitarie.",
      "hospital": "Un ospedale è una struttura sanitaria per la diagnosi, il trattamento e la cura dei pazienti.",
      "treatment": "Il trattamento comprende metodi o procedure utilizzate per alleviare i sintomi o curare una condizione.",
      "vaccine": "Un vaccino aiuta a proteggerti dalle malattie stimolando il sistema immunitario.",
      "prescription": "Una prescrizione è un ordine medico per un farmaco fornito da un dottore.",
      "symptom": "Un sintomo è una caratteristica fisica o mentale che indica una condizione o una malattia.",
      "diagnosis": "La diagnosi è l'identificazione di una malattia o condizione basata su sintomi e test.",
      "patient": "Un paziente è una persona che riceve cure o trattamenti medici.",
    },
    'fr-FR': {
      "hello": "Bonjour, comment puis-je vous aider aujourd'hui ?",
      "how are you?": "Comment vous sentez-vous aujourd'hui ?",
      "good morning": "Bonjour ! Comment puis-je vous aider ?",
      "heart attack": "Une crise cardiaque est une urgence médicale grave qui nécessite un traitement immédiat.",
      "diabetes": "Le diabète est une condition qui affecte la façon dont votre corps gère la glycémie.",
      "blood pressure": "La pression artérielle est la force du sang contre les parois de vos artères.",
      "asthma": "L'asthme est une condition où les voies respiratoires se rétrécissent et s'enflamment, rendant la respiration difficile.",
      "cancer": "Le cancer est un groupe de maladies où les cellules anormales se divisent sans contrôle.",
      "flu": "La grippe est une maladie respiratoire contagieuse causée par les virus de la grippe.",
      "stroke": "Un AVC se produit lorsque l'approvisionnement en sang d'une partie de votre cerveau est interrompu.",
      "fever": "La fièvre est une élévation temporaire de la température corporelle, souvent due à une infection.",
      "headache": "Un mal de tête est une douleur ou une gêne dans la tête ou la zone du visage.",
      "vomiting": "Les vomissements sont l'expulsion violente du contenu de l'estomac par la bouche.",
      "allergy": "Une allergie est une condition où votre système immunitaire réagit de manière anormale à une substance étrangère.",
      "infection": "Une infection est causée par des micro-organismes nuisibles comme des bactéries, des virus ou des champignons.",
      "surgery": "La chirurgie est une procédure médicale qui implique une incision pour traiter une maladie ou une condition.",
      "medication": "Les médicaments sont des substances utilisées pour traiter ou gérer des conditions de santé.",
      "doctor": "Un médecin est un professionnel de santé formé pour diagnostiquer et traiter des maladies.",
      "nurse": "Une infirmière fournit des soins et un soutien aux patients dans les hôpitaux et autres établissements de santé.",
      "hospital": "Un hôpital est un établissement de santé pour le diagnostic, le traitement et les soins des patients.",
      "treatment": "Le traitement comprend les méthodes ou procédures utilisées pour soulager les symptômes ou guérir une condition.",
      "vaccine": "Un vaccin aide à vous protéger contre les maladies en stimulant votre système immunitaire.",
      "prescription": "Une prescription est une ordonnance médicale pour des médicaments donnée par un médecin.",
      "symptom": "Un symptôme est une caractéristique physique ou mentale qui indique une condition ou une maladie.",
      "diagnosis": "Un diagnostic est l'identification d'une maladie ou d'une condition basée sur des symptômes et des tests.",
      "patient": "Un patient est une personne qui reçoit des soins ou un traitement médical.",
    }
  };
  
  export default locales;
  